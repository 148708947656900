import { ButlerType } from "./Enums";
import { FunctionProductCategory } from "./FunctionProductCategory";
import { OrderAdditions, OrderModifiers, OrderPreparations } from "./Order";
import { GroupHeadingHierachy, PriceOption, ProductGroupPriceAdjustmentType, ProductPriceInfo, ProductTypeId } from "./product";
import { ProductCategoryId } from "./ProductCategoryId";
import { ProductGroupHierachy } from "./ProductGroupHierachy";

export const TransactionTypes = {
  ThirdParty: 1,
  GiftCertificate: 2,
  ButlerItem: 3,
  PaymentRequirement: 4,
  Cancellation: 5,
  MenuOrder: 6,
};

export enum CreditCardId {
  Visa = 1,
  Mastercard = 2,
  Amex = 3,
  Discover = 4,
  JCB = 5,
  DinersClub = 6,
  UnionPay = 7,
  AliPay = 8,
}

export const CreditCardTypes = [
  {
    id: 1,
    name: "Visa",
  },
  {
    id: 2,
    name: "Mastercard",
  },
  {
    id: 3,
    name: "Amex",
  },
  {
    id: 4,
    name: "Discover",
  },
  {
    id: 5,
    name: "JCB",
  },
  {
    id: 6,
    name: "Diners Club",
  },
  {
    id: 7,
    name: "Union Pay",
  },
  {
    id: 8,
    name: "Ali Pay",
  },
];

/**
 * @deprecated The method should not be used, instead use productCategoryId
 */
export enum LineItemTypeId {
  /**  id1: productId, id2: productSizeId, id3: menuId, id4: courseId */
  Butler = 1,
  /**  id1: menuId, id2: courseId, id3: productId, id4: orderItemId, id5: orderId ?? */
  Menu = 2,
  /** id1: productId,
     id2: sizeId,
   id3: orderItemId,
   id4: orderId,
   id6: menuHeadingId ??
   id7: menuId ??,
   id8: foodPackageId ??,
   id9: beveragePackageId ??,
   id10: foodAndBeveragePackageId ??
   id11: beverageMenuPackageId ?? */
  Product = 3,
  /** id1: productId, id2: sizeId, id3: packageId, id4: beverageMenuId */
  Function = 6,
  /**  id1: menuId, id2: courseId, id3: productId, id4: orderItemId, id5: orderId ?? */
  Deposit = 7,
  Cashout = 8,
  /**  id1: productId, id2: packageId, id4: orderItemId, id5: orderId ?? */
  Package = 9,
  /**  id1: giftCertificateId
   id2: accountId */
  GiftCertificate = 10,
}

export interface OrderSetup {
  intentId: string;
  used: boolean;
}

export interface OrderSetups {
  [orderSetupId: string]: OrderSetup;
}

export interface GiftCertificateSetups {
  [id: string]: GiftCertificateSetup;
}

export interface GiftCertificateSetup {
  intentId: string;
  used: boolean;
}

export interface FunctionSetup {
  intentId: string;
  used: boolean;
}

export interface FunctionSetups {
  [functionSetupId: string]: FunctionSetup;
}

export interface CustomerTips {
  type: "%" | "$";
  value: number;
}

export interface LineItemFixedMenuSplit {
  food: { standardPrice: number; total: number; salesTax: number };
  beverage: { standardPrice: number; total: number; salesTax: number };
}

export interface CancellationRule {
  from: number;
  to: number;
  unitAdult: number;
  adultAmount: number;
  unitChild?: number;
  childAmount?: number;
}

export interface PaymentSummary {
  lineItems: Array<LineItem>;
  paymentTypeId: PaymentTypeId;
  surcharges?: TransactionSurcharge[];
  serviceCharge?: TransactionSurcharge;
  giftCertificateRedemption: GiftCertificateRedemption;
  voucherRedemption: VoucherRedemption;
  promoCodeRedemption: PromoCodeRedemption;
  depositAppliedAmount?: number;
  depositTabId?: string;
  purchaseAmount: number; // customAmount (from till) || lineItemTotal + surcharges+serviceFee + redemptions + priceadjustments(till) + depositAmount
  tipAmount?: number;
  tipSalesTax?: number;
  serviceFeeAmount: number; // Amount based on percentage purchaseAmount + tipAmount
  cashoutAmount: number;
  grandTotal: number;
  subTotal?: number;
  resbutlerFeeAmount: number;
  totalSalesTax: number;
  totalStandardSalesTax: number;
  paymentPriceAdjustments?: PaymentPriceAdjustment[];
  requiredPaymentTypeId?: number;
  noShow?: {
    unitAdult: number;
    unitChild: number;
    childAmount: number;
    adultAmount: number;
  };
  cancellationRules?: CancellationRule[];
  depositTabAllocations?: TillDepositTabAllocations;
  appliedPurchases /** @todo: Refactor all purchase types to avoid circular dependency */?;
}
export interface LineItem {
  id: string;
  customerId?: string;
  name?: string;
  description?: string;
  inclusive?: boolean;
  inclusiveProduct?: boolean;
  reason?: string;
  modifiers?: OrderModifiers;
  modifierLineItems?: LineItem[];
  additions?: OrderAdditions;
  additionsLineItems?: LineItem[];
  preparations?: OrderPreparations;
  addOns?: { name: string; price: number }[];
  requirements?: Array<string>;
  id1: string;
  id2?: string;
  id3?: string;
  id4?: string;
  id5?: string;
  id6?: string;
  id7?: string;
  id8?: string;
  id9?: string;
  id10?: string;
  id11?: string;
  text?: string[];
  combinations?: LineItem[]; // only 1 deep
  standardPrice: number; // standard restaurant price of item including amended standard prices for modifiers + additions + combo products
  price: number; // price factoring adjustments and menu upgrades of item including amended prices for modifiers + additions + combo products
  priceWithoutAmendments: number; // price factoring adjustments and menu upgrades of item
  totalWithoutAmendments: number; // total cost factoring adjustments and menu upgrades based on item and quantity
  standardPriceWithoutAmendments: number; // standard restaurant price of item
  standardTotalWithoutAmendments: number; // total standard restaurant cost based on item and quantity
  modifiersTotal?: number; // total cost of all item modifiers factoring adjustments and menu upgrades
  modifiersStandardTotal?: number; // total standard restaurant cost of all item modifiers
  additionsTotal?: number; // total cost of all item additions factoring adjustments and menu upgrades
  additionsStandardTotal?: number; // total standard restaurant cost of all item additions
  combinationsTotal?: number; // total cost of all combo products factoring adjustments and menu upgrades
  combinationsStandardTotal?: number; // total standard restaurant cost of all combo products
  butlerTypeId?: ButlerType;
  quantity: number;
  salesTax?: number;
  standardSalesTax?: number;
  nettExSalesTax?: number;
  standardTotal: number; // total standard restaurant cost based on item and quantity including amended standard prices for modifiers + additions + combo products
  total: number; // total cost factoring adjustments and menu upgrades based on item and quantity including amended standard prices for modifiers + additions + combo products
  /**
   * @deprecated The method should not be used, instead use productCategoryId
   */
  lineItemTypeId: LineItemTypeId;
  functionLineItemCategoryId?: FunctionProductCategory;
  productGroupHierachy: ProductGroupHierachy[];
  groupHeadingHierachy?: GroupHeadingHierachy[];
  /** @deprecated */
  priceWithoutAdjustment?: number;
  /** @deprecated */
  priceWithoutAdjustmentTotal?: number;
  productCategoryId: ProductCategoryId;
  fixedMenuPriceSplit?: LineItemFixedMenuSplit;
  paymentPriceAdjustments?: PaymentPriceAdjustment[];
  productTypeId?: ProductTypeId;
  productPriceInfo?: ProductPriceInfo;
} // & (ProductLineItemType | RefundOrManualLineItemType);

// type ProductLineItemType = {
//   LineItemTypeId: LineItemTypeId.Product;
//   id1: string; //productId
//   id2: string; //sizeId
//   id3: string; //orderItemId
//   id4: string; //orderId
//   id6?: string; //menuHeadingId
//   id7?: string; //menuId
//   id8?: string; // foodPackageId ??,
//   id9?: string; // beveragePackageId ??,
//   id10?: string; // foodAndBeveragePackageId ??
//   id11?: string; // beverageMenuPackageId ??
// };

// type RefundOrManualLineItemType = {
//   LineItemTypeId: LineItemTypeId.Refund | LineItemTypeId.Manual;
//   id1: string; //accountId
//   id2: string; //productId
//   id3: string; //productSizeId
//   id4: string; //menuId
// };

export interface PaymentSession {
  bookingId: string;
  customerId: string;
  customerTips: CustomerTips;
  redemptions: Redemptions;
  id?: string;
}
export interface VoucherRedemption {
  message?: string;
  voucherCode: string;
  valid?: boolean;
  redeemAmount?: number;
  voucherType?: number;
  productsRedeemed?: any;
  salesTax?: number;
}

export interface GiftCertificateRedemption {
  message?: string;
  giftCertificateCode: string;
  voucherValid?: boolean;
  redeemAmount?: number;
  giftCertificateAmount?: number;
}

export interface PromoCodeRedemption {
  message?: string;
  voucherCode: string;
  valid?: boolean;
  redeemAmount?: number;
  salesTax?: number;
}

export interface Redemptions {
  voucherRedemption?: VoucherRedemption;
  giftCertificateRedemption?: GiftCertificateRedemption;
  promoCodeRedemption?: PromoCodeRedemption;
}

export interface TillDepositTabAllocations {
  [customerId: string]: { depositAllocationAmount: number };
}

export interface GiftCertificatePaymentSummary {
  lineItems: LineItem[];
  grandTotal: number;
  serviceFee: number;
  resbutlerFee: number;
}

export enum PaymentTypeId {
  Cash = 1,
  StripeCNP = 2,
  Offline = 3,
  GiftCertficate = 4,
  Eftpos = 7,
  Tab = 8,
  ManualBankTransfer = 9,
  Deposit = 10,
  CreditAccount = 11,
  Contra = 12,
  ManualCreditCard = 13,
  ManualCash = 14,
  StripeBECSBankTransfer = 15,
}

export interface PayLaters {
  [orderId: string]: PayLater;
}

export interface PayLater {
  orderItems: {
    [orderItemId: string]: {
      payLater: boolean;
      payNow: boolean;
      time: number;
    };
  };
  mealId: string;
  bookingId: string;
  customerId: string;
  restaurantId: string;
  paymentSummary?: PaymentSummary;
  note?: string;
  date: string;
}

export interface ServiceSurcharge {
  id: string;
  name: string;
  priceOptionId: string;
  priceOption: PriceOption;
}
export interface TransactionSurcharge extends ServiceSurcharge {
  // note id in TransactionSurcharge is financialProductId
  _key: string;
  value: number;
  salesTax?: number;
  lineItemSurcharges?: LineItemSurcharges;
}

export interface PaymentType {
  _key: string;
  paymentTypeId: PaymentTypeId;
  purchaseId?: string;
  transactionRefId?: string;
  value: number;
  manualCreditCardTypeId?: CreditCardId;
  card?: string;
  cardHolderName?: string;
  brand?: string;
  cashReceived?: number;
  currency?: string;
  transactionLocationType?: string;
  timeStamp: number;
  country?: string;
  tipAmount?: number;
  cashoutAmount?: number;
  serviceFee?: number;
  transactionFee?: number;
  cashChange?: number;
  tabId?: string;
  note?: string;
  bankReference?: string; // if paymenttypeid is bankTransfer
  customerId?: string; // if payment type is tab
  accountGroupId?: string;
  accountProductId?: string;
  refundHandlingFee?: number;
  refundChargeId?: string;
  bankBsbNumber?: string;
  bankAccountLastDigit?: string;
  bankDebitMandateId?: string;
  isProcessing?: boolean /** If true then create a pending transaction in different collection than original transaction collection */;
  fingerprint?: string;
}

export interface PaymentPriceAdjustmentPayload {
  _key: string;
  orderItemId?: string;
  change: number;
  type: "$" | "%";
  accountGroupId: string;
  accountProductId: string;
  accountGroupName: string;
  accountProductName: string;
  accountGroupAdjustmentType: ProductGroupPriceAdjustmentType;
}

export interface PaymentPriceAdjustment {
  _key: string;
  /**@deprecated  if its against lineitemid then its already stored on lineitem. so need to store lineitemid*/
  lineItemId?: string;
  change: number;
  type: "$" | "%";
  accountGroupId: string;
  accountProductId: string;
  accountGroupName: string;
  accountProductName: string;
  amount: number;
  accountGroupAdjustmentType: ProductGroupPriceAdjustmentType;
  salesTax?: number;
}
export interface Transaction {
  _key?: string;
  id?: string;
  bookingId: string;
  /** @deprecated  */
  customerId: string;
  date: string;
  functionId?: string;
  grandTotal: number;
  invoiceId?: string;
  lineItems: LineItem[];
  mealId: string;
  operatorId: string;
  orderNumber?: number;
  /**   if the price adjustment is against lineitem then it is also stored directly on lineitem.
   * Currently price adjustment against lineItem is also saved here but it is to be removed in future as its duplicate and is already stored in lineItem */
  paymentPriceAdjustments?: PaymentPriceAdjustment[];
  payments: Array<PaymentType>;
  promoCode: string;
  promoCodeAmount: number;
  resbutlerFee: number;
  restaurantId: string;
  revenueTimeStamp?: number;
  salesTax: number;
  serviceFee: number;
  serviceCharge?: TransactionSurcharge;
  surcharges?: TransactionSurcharge[];
  tillId?: string;
  time: string;
  timeStamp: number;
  tipAmount: number;
  transactionFee: number;
  transactionIdRef: string[];
  transactionNumber: number;
  refundHandlingFee: number;
  voucherAmount: number;
  voucherCode: string;
  voucherType?: number;
  voucherRedeemedItems?: TransactionVoucherRedeemedItem[];
  closedTabId?: string;
  voucherSalesTax?: number;
  promoSalesTax?: number;
  tipSalesTax?: number;
}
export interface PettyCashLineItem {
  id: string;
  productId: string;
  sizeId: string;
  name: string;
  description: string;
  quantity: number;
  salesTax: number;
  price: number;
  total: number;
  productGroupHierachy: ProductGroupHierachy[];
  productCategoryId: ProductCategoryId;
  overrideSalesTax: boolean;
}
export interface PettyCashTransaction {
  _key?: string;
  date: number;
  mealId: string;
  restaurantId: string;
  tillId: string;
  lineItems: PettyCashLineItem[];
  timeStamp: number;
  grandTotal: number;
  operatorId: number;
  transactionNumber: number;
  salesTax: number;
  note?: string;
  receiptImage?: {
    name: string;
    fileName: string;
    sizeFile: number;
    thumbUrl: string;
    type: string;
    url: string;
  };
}
export interface TransactionVoucherRedeemedItem {
  lineItemId: string;
  amountRedeemed: number;
}

export interface FunctionPaymentSummary {
  discount?: number;
  discountSalesTax?: number;
  purchaseAmount: number;
  lineItems: LineItem[];
  grandTotal: number;
  serviceFeeAmount: number;
  resbutlerFeeAmount: number;
  totalSalesTax: number;
  totalStandardSalesTax: number;
  invoiceId?: string;
  minimumSpendAdjustment?: number;
  minimumSpendAdjustmentSalesTax?: number;
  minimumSpend?: number;
  surcharge?: number;
  surchargeSalesTax?: number;
  serviceCharge?: number;
  serviceChargeSalesTax?: number;
  requiredPaymentTypeId?: number;
}

export interface CustomerMapping {
  customerId: string;
  restaurantId: string;
  stripeCustomerId: string;
}

export interface CustomerMappings {
  [mappingId: string]: CustomerMapping;
}

export enum CashPaymentSetting {
  NoCash = "No Cash Restaurant - Pay as you go",
  PayAtTheEnd = "Pay at end with cash",
  PayAsYouGo = "Pay now",
}

export type CashPaymentConfig = {
  setting: CashPaymentSetting;
  visible: boolean;
  allow: boolean;
  label: CashPaymentSetting.NoCash | CashPaymentSetting.PayAsYouGo | CashPaymentSetting.PayAtTheEnd;
};

export interface LineItemSurcharges {
  [lineItemId: string]: LineItemSurcharge;
}
export interface LineItemSurcharge {
  value: number;
  salesTax?: number;
}

export interface MealCashPayment {
  enabled?: boolean;
}

export enum PendingTransactionProcessingStatus {
  Succeeded = "succeeded",
  Failed = "failed",
  Processing = "processing",
}
export interface PendingTransaction extends Transaction {
  processingPaymentRef: string;
  processingPaymentLatestStatus: PendingTransactionProcessingStatus;
  processingPaymentLatestMessage?: string;
}

export interface PendingTransactions {
  [id: string]: PendingTransaction;
}
